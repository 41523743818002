<template>
  <v-container fluid>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="4" class="#faf2df" style="background-color: #f3eee8">
        <!-- title for mobile and desktop screen starts -->
        <div
          v-if="Object.keys(singleInvoice).length"
          :style="{
            display: `${$vuetify.breakpoint.mdAndDown ? 'flex' : 'block'}`,
          }"
        >
          <p class="pt-8 pt-md-13 px-6 mb-md-0 sub__title">Total amount</p>
          <p class="pt-5 pt-md-0 px-6 total__amount text-h6 text-md-h4">
            {{ singleInvoice.total | currency(currencyDefault) }}
          </p>
          <!-- {{ allInvoices.allInvoices }} -->
        </div>
        <!-- title for mobile and desktop screen ends -->

        <!-- Exception summary card starts here -->
        <div v-if="isAnException" class="px-6">
          <v-card
            width="100%"
            min-height="128"
            flat
            elevation="0"
            style="
              background: #ffffff;
              border: 1px solid #ff6a6a;
              box-sizing: border-box;
              border-radius: 4px;
            "
          >
            <div>
              <div
                class="px-4 py-2 d-flex justify-space-between"
                style="border: 1px solid rgba(212, 216, 223, 0.377431)"
              >
                <span class="error--text font-weight-bold">Exception(s)</span>
                <v-avatar color="#FF6A6A" size="22">
                  <span class="white--text text-caption">{{
                    NumOfExceptions
                  }}</span>
                </v-avatar>
              </div>

              <div class="d-flex flex-wrap py-8 px-4">
                <v-chip-group column>
                  <v-chip
                    v-for="tag in exceptionTags"
                    :key="tag"
                    class="px-2 my-1 mx-1"
                    small
                    color="#FFEBEB"
                    text-color="#B33900"
                  >
                    <span class="px-1">{{ tag }}</span>
                  </v-chip>
                </v-chip-group>
              </div>
            </div>
          </v-card>

          <div class="d-flex justify-end mt-4">
            <v-btn
              text
              class="text-capitalize mr-2"
              rounded
              style="background-color: #ffffff"
            >
              <img
                :src="require('@/assets/pbot_icons/pen_flat.svg')"
                class="navIcon pr-2"
              />
              <span class="text-caption text-capitalize">Notify Vendor</span>
            </v-btn>
            <v-btn
              @click="reviewFields"
              text
              class="text-capitalize"
              rounded
              style="background-color: #ffffff"
            >
              <img
                :src="require('@/assets/pbot_icons/pen_flat.svg')"
                class="navIcon pr-2"
              />
              <span class="text-caption text-capitalize">Review</span>
            </v-btn>
          </div>
        </div>
        <!-- Exception summary card starts here -->

        <!-- BasicData component added here... -->
        <BasicData
          class="mt-md-16"
          ref="basicData"
          @downloadClicked="downloadPDF"
        />
        <!-- End of BasicData -->
      </v-col>

      <!-- section for uploaded invoice image starts here  also not shown in mobile -->
      <v-col cols="12" md="8">
        <v-layout class="d-flex flex-column">
          <div class="d-flex pr-md-5" style="align-items: center; width: 100%">
            <v-breadcrumbs :items="breadcrumbs" class="px-md-2">
              <template v-slot:divider>
                <v-icon class="px-0">mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.go(-1)"
              text
              rounded
              large
              class="mt-10"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-icon left>mdi-chevron-left</v-icon> Back
            </v-btn>
          </div>
          <div class="d-flex pr-md-5" style="align-items: center; width: 100%">
            <v-container>
              <v-card
                elevation="3"
                min-height="100vh"
                color="white"
                min-width="100%"
                max-width="1000px"
                v-if="singleInvoice.invoicetype == 'vendor'"
              >
                <v-skeleton-loader
                  width="100%"
                  height="100%"
                  type="image,table"
                  :loading="loading"
                >
                  <v-img
                    class="mx-auto align-center"
                    max-width="90%"
                    max-height="100%"
                    contain
                    :src="invoiceImage"
                  >
                  </v-img>
                </v-skeleton-loader>
              </v-card>
              <v-card
                background-color="#ffffff"
                elevation="3"
                style="width: 100%; min-height: 700px"
                v-if="singleInvoice.invoicetype == 'customer'"
              >
                <v-skeleton-loader
                  width="100%"
                  height="80vh"
                  type="image,table"
                  :loading="loading"
                >
                  <InvoiceTemplate
                    ref="customerInvoice"
                    :company_from="singleInvoice.organization.orgname"
                    :address_from="singleInvoice.organization.office[0].address"
                    :address_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.address
                        : 'customer address'
                    "
                    :invoicenumber="singleInvoice.invoicenumber"
                    :company_to="companydetails"
                    :total="singleInvoice.total"
                    :subTotal="singleInvoice.subtotal"
                    :lines="singleInvoice.lines"
                    :city_from="
                      singleInvoice.organization.office[0].city || 'city'
                    "
                    :city_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.city
                        : 'city'
                    "
                    :country_from="
                      singleInvoice.organization.office[0].country || 'country'
                    "
                    :country_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.country
                        : 'country'
                    "
                    :zip_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.postcoade
                        : 'zip'
                    "
                    :zip_from="
                      singleInvoice.organization.office[0].postcode || 'zip'
                    "
                    :organizationLogo="singleInvoice.organization.logo.url"
                    :dueDate="singleInvoice.due_date || ''"
                    :date="singleInvoice.created_at | moment('DD-MM-YYYY')"
                    :bankName="
                      customerBankDetails ? customerBankDetails.bankname : ''
                    "
                    :bankNumber="
                      customerBankDetails
                        ? customerBankDetails.accountnumber
                        : ''
                    "
                    :tax="singleInvoice.tax || 0.0"
                  />
                </v-skeleton-loader>
              </v-card>
            </v-container>
          </div>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BasicData from "../../includes/BasicData.vue";
import { mapState, mapGetters } from "vuex";
import InvoiceTemplate from "./components/invoiceTemplate.vue";
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "Invoice",
  data() {
    return {
      isAnException: false,
      Exceptions: [],
      isDisabled: false,

      loading: true,
      breadcrumbs: [
        {
          text: "Inbox",
          to: "/inbox",
          disabled: true,
        },
        {
          text: "Invoice",
          to: "#",
          disabled: true,
        },
        {
          text: "...",
          disabled: false,
        },
      ],
    };
  },
  mixins: [currencyFilter],

  methods: {
    async getInvoiceData() {
      try {
        const { data } = await this.$store.dispatch(
          "invoices/getInvoiceById",
          this.$route.params.id
        );
        if (data.invoicetype === "customer") {
          // get the customer bank details
          await this.$store.dispatch("contacts/getCustomerBankDetails", {
            organizationId: this.organizationId,
            customerId: data.customer.id,
          });
        }
        this.breadcrumbs[2].text = data.invoicenumber || "...";
      } catch (error) {
        console.log(JSON.stringify(error, null, 2));
      }
    },
    reviewFields() {
      this.$refs.basicData.changeFieldStatus();
    },

    downloadPDF() {
      this.$refs.customerInvoice.downloadPDF();
    },
  },
  async mounted() {
    this.isAnException = this.$route.query.exception || false;
    await this.getInvoiceData();
  },
  created() {
    //make skeleton loader stop
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  },
  components: {
    BasicData,
    InvoiceTemplate,
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      organizationId: "auth/organizationId",
      invoiceArrayStatus: "invoices/checkInvoiceArray",
      totalInvoice: "invoices/numOfInvoices",
      singleInvoice: "invoices/getSingleInvoice",
      customerBankDetails: "contacts/getCustomerBankDetails",
    }),
    ...mapState({
      organization: "organization",
      formCards: "formBuilder",
    }),

    NumOfExceptions() {
      return Object.keys(this.singleInvoice.exception_fields).length;
    },
    exceptionTags() {
      const arrayValues = Object.values(this.singleInvoice.exception_fields);
      return arrayValues.map((item) => item.replaceAll("_", " "));
    },

    invoiceImage() {
      const value = this.singleInvoice.invoiceimage;
      return "data:image/png;base64," + value;
    },

    // get the company details
    companydetails() {
      // if (this.singleInvoice.customer?.company) {
      //   return this.singleInvoice.customer.company.company_name;
      // } else {
      //   return this.singleInvoice.customer.email;
      // }
      return this.singleInvoice.customer.email;
    },
  },
};
</script>

<style scoped>
.v-divider--inset:not(.v-divider--vertical) {
  margin-left: -20px !important;
}

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 100px !important;
}

.v-text-field {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: transparent;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 2px;
  margin-top: 8px;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(25, 40, 61, 0.8);
}

.total__amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  color: #455160;
}

.v-breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  list-style-type: none;
  margin-top: 16px;
  padding: 18px 8px;
  font-size: medium !important;
}

.v-breadcrumbs li {
  align-items: center !important;
  display: inline-flex !important;
  font-size: medium !important;
  font-weight: 600 !important;
}

/* media queries for tablets */
@media only screen and (max-width: 768px) {
  .v-breadcrumbs {
    padding: 18px 20px;
  }
}

/* media queries for desktop */
@media only screen and (min-width: 768px) {
  .v-breadcrumbs {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    list-style-type: none;
    margin-top: 42px;
    padding: 18px 70px;
    font-size: large !important;
  }
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
