<template>
  <div>
    <div>
      <v-form ref="form">
        <div>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold">Invoice no.</span>
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                <v-text-field :rules="rules.required" v-model="payableData.invoicenumber" hide-details="auto"
                  :disabled="changeState" :background-color="backgroundColor" solo flat dense
                  :class="!changeState ? changeState : ''" reverse class="ma-0 pa-0 input__field_style"
                  placeholder="Enter Invoice">
                </v-text-field>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold">Date</span>
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="payableData.date" hide-details="auto" :disabled="changeState"
                      :background-color="backgroundColor" solo flat dense v-bind="attrs" v-on="on"
                      :class="[!changeState ? changeState : '']" reverse class="input__field_style"
                      placeholder="Enter Date"></v-text-field>
                  </template>
                  <v-date-picker v-model="payableData.date" @input="menu = false" no-title scrollable :min="minDate">
                  </v-date-picker>
                </v-menu>
              </v-skeleton-loader>
            </v-col>

            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold">VAT</span>
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                <div class="d-flex justify-end align-center">
                  <v-switch v-model="payableData.vat" hide-details="auto" :disabled="changeState"
                    :class="[!changeState ? changeState : '']" class="input__field_style"></v-switch>
                </div>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mb-mb-8 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold">Due Date</span>
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="payableData.due_date" hide-details="auto" :disabled="changeState"
                      :background-color="backgroundColor" solo flat dense v-bind="attrs" v-on="on"
                      :class="[!changeState ? changeState : '']" reverse class="input__field_style"
                      placeholder="Enter Due Date">
                    </v-text-field>
                  </template>
                  <v-date-picker @input="menu2 = false" no-title scrollable v-model="payableData.due_date" :min="minDate">
                  </v-date-picker>
                </v-menu>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold">Currency</span>
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                <v-select :rules="rules.required" v-model="payableData.currency" hide-details="auto"
                  :disabled="changeState" :background-color="backgroundColor" solo flat :items="currencyItems" dense
                  :class="[!changeState ? changeState : '']" reverse class="input__field_style"
                  placeholder="Set Currency"></v-select>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>

          <div>
            <p class="py-5 mt-4 px-6 invoiceType" v-if="singleInvoice.invoicetype == 'vendor'">
              Vendor Details
            </p>
            <p v-if="singleInvoice.invoicetype == 'customer'" class="py-5 mt-4 px-6 invoiceType">
              Customer Details
            </p>

            <div>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold">Name</span>
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                    <v-text-field :rules="rules.required" v-model="payableData.vendor.vendorname" hide-details="auto"
                      disabled background-color="transparent" solo flat dense reverse class="input__field_style"
                      placeholder="Enter Name"></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold">Address</span>
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                    <v-text-field :loading="loadingdata" v-model="payableData.vendor.address" hide-details="auto" disabled
                      background-color="transparent" solo flat dense reverse class="input__field_style"
                      placeholder="Enter Address"></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold">Tax Id.</span>
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                    <v-text-field :loading="loadingdata" v-model="payableData.vendor.taxid" hide-details="auto" disabled
                      background-color="transparent" solo flat dense reverse class="input__field_style"
                      placeholder="Enter Tax Id."></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold">Email</span>
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                    <v-text-field :loading="loadingdata" v-model="payableData.vendor.email" hide-details="auto" disabled
                      background-color="transparent" solo flat dense reverse class="input__field_style"
                      placeholder="Enter your Email"></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold">Phone</span>
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader width="100%" type="text" :loading="loadingdata">
                    <v-text-field :loading="loadingdata" v-model="payableData.vendor.contact.phone" hide-details="auto"
                      disabled background-color="transparent" solo flat dense reverse class="input__field_style"
                      placeholder="Enter Phone"></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
            </div>
          </div>
          <div class="pl-6 pr-4 pt-6">
            <p class="mb-0 pb-6 invoiceType">Invoice Items</p>

            <div v-for="(line, index) in singleInvoice.lines" :key="`Input-${index}`">
              <v-row>
                <v-col cols="12" sm="6" md="7" class="pr-md-0 pb-1 pb-md-4">
                  <v-text-field v-model="line.description" hide-details="auto" :disabled="changeState"
                    :background-color="backgroundColor" :rules="rules.required" solo flat dense placeholder="Item">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="pl-md-0 pt-1 pt-md-3">
                  <div class="d-flex d-md-flex align-center pl-1">
                    <v-btn v-if="singleInvoice.lines[index].quantity > 0" x-small color="primary" :disabled="changeState"
                      icon @click="decreaseQty(index)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <p class="quantity mb-0 px-2">{{ line.quantity }}</p>
                    <v-btn x-small color="primary" :disabled="changeState" icon @click="increaseQty(index)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-text-field class="ml-1" v-model.number="line.price" hide-details="auto" :disabled="changeState"
                      :background-color="backgroundColor" :rules="rules.price" solo flat dense placeholder="Price">
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <hr class="hr-line" />
            </div>
            <div class="d-flex justify-space-between pt-1 pb-3">
              <p class="remove__text text-capitalize" text @click="removeInput(singleInvoice.lines)"
                :disabled="changeState" v-show="singleInvoice.lines && singleInvoice.lines.length > 1">
                - last item
              </p>
              <p></p>

              <p class="link__text text-right text-capitalize" text @click="addInput" :disabled="changeState">
                + New Item
              </p>
            </div>
            <hr class="hr-line" />
          </div>
        </div>

        <div class="px-6" style="padding-bottom: 20px">
          <div class="d-flex align-center justify-center text-center pt-14">
            <v-btn :disabled="isDisabled" @click="triggerModal" large elevation="10" :loading="isSending" color="primary"
              width="100%" height="50px">
              <simple-line-icons style="width: 15.98px; height: 15.97px" class="m-0 text--white" icon="cursor" no-svg />
              <span class="pl-8 text-capitalize" v-if="singleInvoice.invoicetype == 'vendor'">Approve for payment</span>
              <span class="pl-8 text-capitalize" v-else>Send Invoice</span>
            </v-btn>
          </div>
          <InvoiceBtn :showReview="changeState" :isCustomer="singleInvoice.invoicetype == 'vendor' ? false : true"
            @downloadPDF="handlePDFDownload" @clickedSaved="changeFieldStatus" class="pt-8" />
        </div>
      </v-form>
    </div>
    <!-- add to payables modal -->
    <AddToPayModal ref="addtopayable" />
    <SendCustomerInvoice ref="SendInvoiceModal" />
  </div>
</template>

<script>
import SimpleLineIcons from "vue-simple-line";
import InvoiceBtn from "./InvoiceBtn.vue";
import { mapActions, mapGetters } from "vuex";
import AddToPayModal from "./overlays/AddToPayModal.vue";
import SendCustomerInvoice from "../pages/invoices/components/sendCustomerInvoice.vue";

export default {
  name: "BasicData",
  data() {
    return {
      menu: false,
      menu2: false,
      loadingdata: false,
      isSending: false,
      changeState: true,
      isSaved: false,
      saveData: true,
      vendorData: {
        name: "",
        address: "",
        taxId: "",
        email: "",
        phone: "",
      },
      customerData: {
        name: "",
        address: "",
        email: "",
        phone: "",
      },
      sendInvoiceData: {
        customer: {
          customername: "",
          address: "",
          taxid: "",
          email: "",
          contact: {
            phone: "",
          },
        },
      },
      payableData: {
        vendor: {
          vendorname: "",
          address: "",
          taxid: "",
          email: "",
          contact: {
            phone: "",
          },
        },
        invoicenumber: "",
        vat: "",
        currency: "",
        date: null,
        due_date: this.$moment().format("YYYY-MM-DD"),
      },
      rules: {
        required: [(v) => !!v || "This field is required"],
        email: [
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
          (v) => !!v || "E-mail is required",
        ],
        phone: [
          (v) => !!v || "Phone is required",
          (v) => v.length >= 11 || "Phone must be at least 11 characters",
        ],
      },
      currencyItems: [
        "NGN",
        //   "USD"
      ],
      lines: [],
    };
  },
  components: {
    SimpleLineIcons,
    InvoiceBtn,
    AddToPayModal,
    SendCustomerInvoice,
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    handlePDFDownload() {
      this.$emit("downloadClicked");
    },
    changeFieldStatus() {
      if (this.changeState) {
        this.changeState = false;
        this.saveData = true;
      } else {
        this.changeState = true;
        this.saveInvoice();
      }
      if (process.env.NODE_ENV === "development") {
        console.log(this.updateInvoicePayload);
      }
    },

    addInput() {
      this.singleInvoice.lines.push({
        description: "",
        quantity: 1,
        price: 0,
      });
    },
    removeInput(inputType) {
      if (inputType.length > 0) {
        inputType.pop(); // Remove the last item
      }
      // console.log(inputType);
    },

    //increase quantity
    increaseQty(index) {
      this.singleInvoice.lines[index].quantity++;
    },
    //decrease quantity
    decreaseQty(index) {
      if (this.singleInvoice.lines[index].quantity > 1) {
        this.singleInvoice.lines[index].quantity--;
      }
    },

    //save and update invoice data
    async saveInvoice() {
      try {
        await this.$store
          .dispatch("invoices/updateInvoiceById", {
            id: this.$route.params.id,
            payload: this.updateInvoicePayload,
          })
          .then(() => {
            this.showToast({
              sclass: "success",
              show: true,
              message: "Invoice Saved Successfully",
              timeout: 3000,
            });
          });
      } catch (error) {
        // console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "Error Saving Invoice",
          timeout: 3000,
        });
      } finally {
        this.saveData = false;
        this.isSaved = true;
      }
    },

    triggerModal() {
      if (this.$refs.form.validate()) {
        if (!this.isSaved) {
          this.saveInvoice();
          if (this.singleInvoice.invoicetype == "vendor") {
            this.changeFieldStatus();
            this.$refs.addtopayable.openDialog();
          } else {
            this.$refs.SendInvoiceModal.show(true);
          }
        } else {
          if (this.singleInvoice.invoicetype == "vendor") {
            this.changeFieldStatus();
            this.$refs.addtopayable.openDialog();
          } else {
            this.$refs.SendInvoiceModal.show(true);
          }
        }
      } else {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Please fill all required fields",
          timeout: 3000,
        });
      }
      //console.log(this.$refs.addtopayable.openDialog());
    },
  },

  computed: {
    ...mapGetters({
      singleInvoice: "invoices/getSingleInvoice",
    }),

    backgroundColor() {
      return this.changeState ? "transparent" : "#ffffff";
    },
    //format payload to update invoice
    updateInvoicePayload() {
      if (this.singleInvoice.invoicetype == "vendor") {
        return {
          ...this.payableData,
          vendor: this.singleInvoice.vendor.id,
        };
      } else
        return {
          customer: this.singleInvoice.customer.id,
          invoicenumber: this.payableData.invoicenumber,
          vat: this.payableData.vat,
          currency: this.payableData.currency,
          date: this.payableData.date,
          due_date: this.payableData.due_date,
        };
    },
    formatedDate() {
      const date = new Date(this.singleInvoice.created_at);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return day + "/" + month + "/" + year;
    },
    formatedDueDate() {
      if (this.singleInvoice.due_date) {
        return this.$moment(this.singleInvoice.due_date).format("DD/MM/YYYY");
      } else return this.$moment().format("DD/MM/YYYY");
    },

    isDisabled() {
      if (this.$route.query.exception) {
        return this.saveData;
      } else {
        //switch statement to check if invoice has been sent
        switch (this.singleInvoice.status) {
          case "sent":
            return true;
          case "paid":
            return true;
          case "overdue":
            return true;

          default:
            return false;
        }
      }
    },
    singleInvoiceArray() {
      return Object.entries(this.singleInvoice).map((e) => ({
        [e[0]]: e[1],
      }));
    },
    //get minimum date using moment
    minDate() {
      return this.$moment(this.singleInvoice.created_at).format("YYYY-MM-DD");
    },
  },

  mounted() {
    this.loadingdata = true;
    setTimeout(
      () => {
        if (this.$route.query.review) {
          this.changeState = false;
        }
        this.loadingdata = false;
      },
      3000,
      this.singleInvoice
    );
  },

  watch: {
    singleInvoice: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != null) {
          if (val.invoicetype == "vendor") {
            this.payableData.vendor.vendorname = val.vendor?.vendorname;
            this.payableData.vendor.address = val.vendor?.address;
            this.payableData.vendor.taxid = val.vendor?.taxid;
            this.payableData.vendor.email = val.vendor?.email;
            this.payableData.vendor.contact.phone =
              val.vendor?.contact?.phone || "";
            this.payableData.invoicenumber = val.invoicenumber;
            this.payableData.vat = val.vat;
            this.payableData.currency = val.currency;
            this.payableData.date = this.$moment(val.created_at).format(
              "DD/MM/YYYY"
            );
            this.payableData.due_date = val.due_date;
          } else if (val.invoicetype == "customer") {
            if (val.customer) {
              this.payableData.vendor.vendorname =
                val.customer?.firstname + " " + val.customer?.lastname || "";
            }

            this.payableData.vendor.address =
              val.customer?.address?.address || "";
            this.payableData.vendor.taxid = val.customer?.tax_number || "";
            this.payableData.vendor.email = val.customer?.email || "";
            this.payableData.vendor.contact.phone =
              val.customer?.contact?.phone || "";
            this.payableData.invoicenumber = val.invoicenumber;
            this.payableData.vat = val.vat;
            this.payableData.currency = val.currency;
            this.payableData.date = this.$moment(val.created_at).format(
              "DD/MM/YYYY"
            );
            this.payableData.due_date = val.due_date || this.formatedDueDate;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.invoiceType {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.hr-line {
  border-top: 1px dashed rgba(40, 28, 75, 0.233);
}

/* @media and (min-width: 900px) {
  
} */

.v-subheader {
  align-items: center;
  display: flex;
  height: 48px;
  font-size: 0.9rem !important;
  font-weight: 400;
  padding: 0 16px 0 16px;
}

.v-text-field {
  width: 260px;
}

.centered-input input {
  text-align: right;
}

i.sli-font {
  font-size: 16px;
  display: inline-block;
}

.v-btn--block {
  display: flex;
  flex: 1 0 auto;
  min-width: 380px !important;
  max-width: auto;
}

.changeState {
  background: #ffffff;
  border: 1px solid rgba(48, 31, 120, 0.07);
}

.input__field_style {
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #7f919b;
}

.defaultState {
  background: transparent;
  border: none;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 38px) !important;
  margin-left: 18px;
}

.quantity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #19283d;
  cursor: pointer;
}

.section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(25, 40, 61, 0.8);
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
  cursor: pointer;
}

.remove__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.v-text-field[data-v-12b9862d] {
  width: 294px !important;
}

/* media queries for large screens */

@media only screen and (min-width: 900px) {
  .invoiceType {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
